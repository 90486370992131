/* Styles from previous version of site, used to fill in colors for svgs */
.pixel--black {
  fill: #000;
}
.pixel--blue {
  fill: #2196f3;
}
.pixel--bookshelf-back {
  fill: #795548;
}
.pixel--brown {
  fill: #744720;
}
.pixel--cream {
  fill: #ffd180;
}
.pixel--dark-brown {
  fill: #5b3a1c;
}
.pixel--dark-green {
  fill: #388e3c;
}
.pixel--dark-leather {
  fill: #3e2723;
}
.pixel--dark-red {
  fill: #dd2c00;
}
.pixel--darkest-brown {
  fill: #432c19;
}
.pixel--denim {
  fill: #3f51b5;
}
.pixel--gray {
  fill: #808080;
}
.pixel--green {
  fill: #4caf50;
}
.pixel--leather {
  fill: #5d4037;
}
.pixel--light-brown {
  fill: #8d5524;
}
.pixel--light-gray {
  fill: #c0c0c0;
}
.pixel--orange {
  fill: #ff5722;
}
.pixel--purple {
  fill: #9c27b0;
}
.pixel--red {
  fill: #f44336;
}
.pixel--shadow-gray {
  fill: #9e9e9e;
}
.pixel--skin {
  fill: #ffcd94;
}
.pixel--stubble {
  fill: #c6915c;
}
.pixel--teal {
  fill: #008080;
}
.pixel--violet {
  fill: #800080;
}
.pixel--white {
  fill: #fff;
}
